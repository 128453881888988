<template>
  <div>
    <div class="logo"></div>
    <form class="login-block" @submit.prevent="login">
      <h1>Login</h1>
      <input
        type="text"
        v-model="username"
        placeholder="Email"
        id="username"
      />
      <input
        type="password"
        v-model="password"
        placeholder="Password"
        id="password"
      />
      <button type="submit">Masuk</button>
    </form>
  </div>
</template>


<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", { password: this.password, username: this.username })
        .then(() => {
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          alert(err.msg);
        });
      this.username = "";
      this.password = "";
    },
  },
};
</script>


<style lang="css" scoped>
/* body {
  background: url("http://i.imgur.com/Eor57Ae.jpg") no-repeat fixed center
    center;
  background-size: cover;
  font-family: Montserrat;
} */

.logo {
  width: 440px;
  height: 57px;
  background: url("https://mountrash.com/_next/static/images/logopanjang-4787df29ac58dd8433cf2fe308748db4.png")
    no-repeat;
  margin: 30px auto;
}

.login-block {
  width: 320px;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  border-top: 5px solid #ff656c;
  margin: 0 auto;
}

.login-block h1 {
  text-align: center;
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
}

.login-block input {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Montserrat;
  padding: 0 20px 0 50px;
  outline: none;
}

.login-block input#username {
  background: #fff url("http://i.imgur.com/u0XmBmv.png") 20px top no-repeat;
  background-size: 16px 80px;
}

.login-block input#username:focus {
  background: #fff url("http://i.imgur.com/u0XmBmv.png") 20px bottom no-repeat;
  background-size: 16px 80px;
}

.login-block input#password {
  background: #fff url("http://i.imgur.com/Qf83FTt.png") 20px top no-repeat;
  background-size: 16px 80px;
}

.login-block input#password:focus {
  background: #fff url("http://i.imgur.com/Qf83FTt.png") 20px bottom no-repeat;
  background-size: 16px 80px;
}

.login-block input:active,
.login-block input:focus {
  border: 1px solid #ff656c;
}

.login-block button {
  width: 100%;
  height: 40px;
  background: #ff656c;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e15960;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  font-family: Montserrat;
  outline: none;
  cursor: pointer;
}

.login-block button:hover {
  background: #ff7b81;
}
</style>
